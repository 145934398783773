import { Component, Mixins, Prop } from "vue-property-decorator";
import UlContentHeader from "../../components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { DetailRequest, UpdateRequest } from "@/api/user/request";
import UserDetail from "@/store/user/detail";
import UserUpdate from "@/store/user/update";
import { DetailResult } from "@/api/user/response";
import _ from "lodash";

@Component({
  components: { UlContentHeader, UlBreadcrumbs, UIDatePicker, UIFormRow }
})
export default class edit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  id!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "ユーザー情報";
  headingSub = "Customer information";
  breadCrumbs = [
    { text: "カスタマー", disabled: true },
    { text: "ユーザー情報一覧", disabled: false, to: { name: "customers" } },
    { text: "編集", disabled: true }
  ];

  // ------------
  // 変動値
  // ------------
  inputParams = {
    /** ユーザーID */
    id: null as number | null,
    /** ユーザー名 */
    name: null as string | null,
    /** 誕生日 */
    birthday: null as string | null,
    /** 電話番号 */
    tel: null as string | null,
    /** メールアドレス */
    mail: null as string | null,
    /** 性別 */
    gender: null as number | null
  } as UpdateRequest;

  get detail() {
    return UserDetail.getDetail;
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    await UserDetail.detail({ id: Number(this.id) } as DetailRequest);
    if (!UserDetail.isSuccess) {
      await Flash.setErrorNow({
        message: UserDetail.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.syncInputParams(UserDetail.getDetail);
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await UserDetail.clearResponse();
    await UserUpdate.clearResponse();
  }

  /**
   * DatePickerが変更された際のコールバック
   *
   * @param date
   */
  changeDateCallback(date: string | null) {
    this.inputParams.birthday = date;
  }

  /**
   * 認証コードを更新する
   */
  async update(): Promise<boolean> {
    await Flash.clear();
    await UserUpdate.update(this.createUpdateRequest());
    if (UserUpdate.isSuccess) {
      await this.redirectWithSuccessAlert(
        "ユーザー情報を保存しました。",
        "/customer-information"
      );
    } else {
      await Flash.setErrorNow({
        message: UserUpdate.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return UserUpdate.isSuccess;
  }

  private syncInputParams(detailResult: DetailResult) {
    this.inputParams.id = detailResult.id;
    this.inputParams.name = detailResult.name;
    this.inputParams.birthday = detailResult.birthday;
    this.inputParams.gender =
      detailResult.gender == null ? null : detailResult.gender;
    this.inputParams.mail = detailResult.mail;
    this.inputParams.tel = detailResult.tel;
  }

  private createUpdateRequest() {
    const request = _.cloneDeep(this.inputParams);
    if (!this.inputParams.name) {
      delete request.name;
    }
    if (!this.inputParams.birthday) {
      delete request.birthday;
    }
    if (!this.inputParams.tel) {
      delete request.tel;
    }
    if (!this.inputParams.mail) {
      delete request.mail;
    }
    return request;
  }
}
