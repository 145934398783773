import service from "@/api/service";
import {
  DeleteRequest,
  DetailRequest,
  ExportRequest,
  SearchListRequest,
  SearchRequest,
  UpdateRequest
} from "@/api/user/request";
import { DetailResponse, SearchResponse } from "@/api/user/response";
import { CommonResponse, DownloadZIPResponse } from "@/api/response";

/**
 * ユーザー一覧・検索APIをコールします。
 *
 * @param searchRequest ユーザー検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-user", searchRequest);
  return response.data as SearchResponse;
}

/**
 * ユーザー一覧・検索APIをコールします。
 *
 * @param searchListRequest ユーザ検索のリクエストボディ
 * @return SearchResponse
 */
export async function list(searchListRequest: SearchListRequest) {
  const response = await service.post("/search-user", searchListRequest);
  return response.data as SearchResponse;
}

/**
 * ユーザー詳細情報取得APIをコールします。
 *
 * @param detailRequest ユーザー詳細情報取得のリクエストボディ
 * @return DetailResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post("/detail-user", detailRequest);
  return response.data as DetailResponse;
}

/**
 * ユーザー情報登録更新APIをコールします。
 *
 * @param updateRequest ユーザー情報登録更新のリクエストボディ
 * @return CommonResponse
 */
export async function update(updateRequest: UpdateRequest) {
  const response = await service.post("/update-user", updateRequest);
  return response.data as CommonResponse;
}

/**
 * ユーザー情報削除APIをコールします。
 *
 * @param deleteRequest
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-user", deleteRequest);
  return response.data as CommonResponse;
}

/**
 * ユーザー情報ダウンロードAPIをコールします。
 *
 * @param exportRequest
 * @return DownloadZIPResponse
 */
export async function exportOne(exportRequest: ExportRequest) {
  const response = await service.postReceiveZIP("/export-user", exportRequest);
  return response as DownloadZIPResponse;
}
